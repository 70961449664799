import Axios from '@/utils/Elk-Axios';
import {GET_LEARNERS} from '@/store/actionsName';
import {
  SET_LEARNERS,
  SET_CURRENT_STUDY_YEAR,
  SET_CURRENT_VALIDATION_PERIOD
} from '@/store/mutationsName';
import {GET_LEARNERS as GET_LEARNERS_API, GET_VALIDATION_PERIODS} from '@/api';

export default {
  namespaced: true,

  state: {
    learners: null,
    currentStudyYear: null,
    currentValidationPeriod: null
  },

  getters: {
    learnersList(state) {
      return state.learners;
    },
    currentStudy(state) {
      return {
        year: state.currentStudyYear,
        period: state.currentValidationPeriod
      };
    }
  },

  mutations: {
    [SET_LEARNERS](state, learners) {
      state.learners = learners;
    },
    [SET_CURRENT_STUDY_YEAR](state, year) {
      state.currentStudyYear = year;
    },
    [SET_CURRENT_VALIDATION_PERIOD](state, period) {
      state.currentValidationPeriod = period;
    }
  },
  actions: {
    async [GET_LEARNERS](context) {
      try {
        const {data} = await Axios.get(GET_LEARNERS_API);
        context.commit(SET_LEARNERS, data);

        context.commit(SET_CURRENT_VALIDATION_PERIOD, 'Весенняя');
        context.commit(
          SET_CURRENT_STUDY_YEAR,
          // 'e87af881-0444-11ec-991b-00505698018b'
          // 'e87af882-0444-11ec-991b-00505698018b'
          // 'ef6e283c-0444-11ec-991b-00505698018b',
          'ef6e283d-0444-11ec-991b-00505698018b'
        );

        return Promise.resolve();
      } catch (e) {
        context.commit(SET_LEARNERS, null);
        return Promise.reject(e);
      }
    }
  }
};
