let components = {
  //main widgets
  'elk.main.gph': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeGph'
    ),
  'elk.main.top_notification': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeImportantNotifications'
    ),
  'elk.registration.main': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkRegistration'
    ),
  'elk.main.quicksearch': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkFastSearch'
    ),
  'elk.main.info.error': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkInfoCoreError'
    ),
  'elk.main.login': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeLogin'
    ),
  'elk.main.banner': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeBanner'
    ),
  'elk.main.mycontacts': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeContacts'
    ),
  'elk.main.claim': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeClaim'
    ),
  'elk.main.task': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeTask'
    ),
  'elk.main.service': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeService'
    ),
  'elk.main.news': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeNews'
    ),
  'elk.main.faq': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeFaq'
    ),
  'elk.main.myservice': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeMyService'
    ),
  'elk.main.schedule': () =>
    import(
      /* webpackChunkName: "main-widgets" */ '@/components/widgets/elkHomeSchedule'
    ),
  'elk.main.studdog': () =>
    import(
      /* webpackChunckName: "studdog" */ '@/components/widgets/elkStuddog'
    ),
  'elk.main.welcome': () =>
    import(
      /* webpackChunckName: "main-widgets" */ '@/components/widgets/elkHomeWelcome/ElkHomeWelcome'
    ),
  'elk.main.olimpstypeni': () =>
    import(
      /* webpackChunckName: "main-widgets" */ '@/components/widgets/elkOlympiads/FormOlimpStypeni.vue'
    ),
  'elk.main.events': () =>
    import(
      /* webpackChunckName: "main-widgets" */ '@/components/widgets/elkHomeEvents'
    ),
  'elk.main.psychologists': () =>
    import(
      /* webpackChunckName: "main-widgets" */ '@/components/widgets/elkPsychologists'
    ),

  //page widgets
  'elk.user_suggestions.main': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkUserSuggestions'
    ),
  'elk.user_suggestions_btn.main': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkUserSuggestionsBtn'
    ),
  'elk.recovery.main': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkRecovery'
    ),
  'elk.claim.claim': () =>
    import(/* webpackChunkName: "widgets" */ '@/components/widgets/elkClaim'),
  'elk.claim.camunda-claims': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/ElkCamunda/ElkCamundaClaims'
    ),

  'elk.task.task': () =>
    import(/* webpackChunkName: "widgets" */ '@/components/widgets/elkTask'),
  'elk.tasks.camunda-tasks': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/ElkCamunda/ElkCamundaTasks'
    ),
  'elk.feedback.feedback': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkFeedback'
    ),
  'elk.body_check.widget': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkBodyCheck'
    ),
  'elk.body_check.detail.widget': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkBodyCheck'
    ),

  'elk.hr.hr': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/elkHrSearch'
    ),
  'elk.news.detail.main': () =>
    import(
      /* webpackChunkName: "news-detail-widgets" */ '@/components/widgets/elkNewsDetail'
    ),
  'elk.notification.notification': () =>
    import(
      /* webpackChunkName: "notification-widgets" */ '@/components/widgets/notifications'
    ),
  'elk.faq.faq': () =>
    import(/* webpackChunkName: "widgets" */ '@/components/widgets/elkFaq'),
  'elk.schedule.schedule': () =>
    import(
      /* webpackChunkName: "schedule-widgets" */ '@/components/widgets/elkSchedule'
    ),
  'elk.stat.main': () =>
    import(
      /* webpackChunkName: "stat-widgets" */ '@/components/widgets/elkStat'
    ),

  //profile widgets
  'elk.profile.workplace': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileWorkplaces'
    ),
  'elk.profile.hse_education': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileEducationHistory'
    ),
  'elk.profile.main': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileMain'
    ),
  'dpo.elk.profile.video': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/dpoElkProfileVideo/dpoElkProfileVideo'
    ),
  // 'elk.profile.passport': () =>
  //   import(
  //     /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfilePassport'
  //   ),
  // 'elk.profile.diploma': () =>
  //   import(
  //     /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileDiploma'
  //   ),
  // 'elk.profile.training': () =>
  //   import(
  //     /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileTraining'
  //   ),
  'elk.profile.security': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileSecurity'
    ),
  'elk.profile.planned_absences': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkPlannedAbsences'
    ),
  'elk.profile.academic_allowances': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkAcademicAllowances/elkAcademicAllowances'
    ),
  'dpo.elk.profile.svo': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/dpoElkProfileSvo/dpoElkProfileSvo'
    ),
  'elk.covid': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkProfileCovid'
    ),

  'elk.main.covid19_block_function': () =>
    import(
      /* webpackChunkName: "profile-widgets" */ '@/components/widgets/elkBlockCovid'
    ),

  //hr-detail widgets
  'elk.hr.detail.main': () =>
    import(
      /* webpackChunkName: "hr-detail-widgets" */ '@/components/widgets/elkHrDetail/elkHrDetailMain'
    ),

  //service widgets
  'elk.service.service': () =>
    import(
      /* webpackChunkName: "service-widgets" */ '@/components/widgets/elkService'
    ),

  'elk.profile.historyactions': () =>
    import(
      /* webpackChunkName: "history-actions-widgets" */ '@/components/widgets/elkHistoryActions'
    ),
  'elk.my_publications.widget': () =>
    import(
      /* webpackChunkName: "widgets" */ '@/components/widgets/postgraduate/myPublications'
    ),

  //postgraduate widgets
  'elk.postgraduate_info.widget': () =>
    import(
      /* webpackChunkName: "postgraduate-info-widgets" */ '@/components/widgets/postgraduate/elkPostgraduateInfo'
    ),

  'elk.postgraduate_identifiers.widget': () =>
    import(
      /* webpackChunkName: "postgraduate-identifiers-widgets" */ '@/components/widgets/postgraduate/elkPostgraduateScientificIdentifiers'
    ),

  'elk.postgraduate_applications.widget': () =>
    import(
      /* webpackChunkName: "postgraduate-applications-widgets" */ '@/components/widgets/postgraduate/elkMyApplications'
    ),

  'elk.postgraduate.iup.widget': () =>
    import(
      /* webpackChunkName: "form-generator" */ '@/components/widgets/postgraduate/AsavIndividualCurriculum/detail'
    ),

  'elk.postgraduate.class_schedule': () =>
    import(
      /* webpackChunkName: "postgraduate" */ '@/components/widgets/postgraduate/classSchedule'
    ),

  'elk.postgraduate.iups.widget': () =>
    import(
      /* webpackChunkName: "iups" */ '@/components/widgets/postgraduate/AsavIndividualCurriculum/list'
    ),

  'elk.postgraduate.research_topics.widget': () =>
    import(
      /* webpackChunkName: "research-topics" */ '@/components/widgets/postgraduate/AsavResearchTopic/list'
    ),

  'elk.postgraduate.research_topic.widget': () =>
    import(
      /* webpackChunkName: "form-generator" */ '@/components/widgets/postgraduate/AsavResearchTopic/detail'
    ),

  'elk.postgraduate.application.widget': () =>
    import(
      /* webpackChunkName: "form-generator" */ '@/components/widgets/postgraduate/AsavApplicationDetail'
    ),
  'elk.my_publication.widget': () =>
    import(
      /* webpackChunkName: "postgraduate-application" */ '@/components/widgets/postgraduate/MyPublication'
    ),

  'elk.research_adviser.task.widget': () =>
    import(
      /* webpackChunkName: "research_adviser" */ '@/components/widgets/researchAdviser/Task/detail'
    ),

  'elk.research_adviser.tasks.widget': () =>
    import(
      /* webpackChunkName: "research_adviser" */ '@/components/widgets/researchAdviser/Task/list'
    ),

  //attestation

  'elk.postgraduate.internship_reports.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/InternshipReport'
    ),

  'elk.postgraduate.research_reports.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/InternshipReport'
    ),

  'elk.postgraduate.attestation_lists.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/elkAttestationSheets'
    ),

  'elk.postgraduate.attestation_lists.detail.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/elkAttestationSheets/detail'
    ),

  'elk.postgraduate.publications.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/Publications'
    ),

  'elk.postgraduate.academic_debt.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/elkAttestation/elkAcademicDebt'
    ),

  'elk.postgraduate.passing_attestation.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/elkAttestation/elkPassingAttestation'
    ),

  'elk.postgraduate.portal_publications.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/PortalPublications/index.vue'
    ),

  'elk.postgraduate.conferences': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/postgraduate/PostgraduateConferences'
    ),

  'elk.kedo.personnel_statements.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/elkPersonnelStatements'
    ),

  'elk.kedo.personnel_statement.widget': () =>
    import(
      /* webpackChunkName: "attestation" */ '@/components/widgets/elkPersonnelStatements/detail'
    ),

  'elk.kedo.status_sev_kedo.widget': () =>
    import(
      /* webpackChunkName: "kedo" */ '@/components/widgets/elkStatusSevKedo'
    ),

  'elk.kedo.personnel_statement_create.widget': () =>
    import(
      /* webpackChunkName: "kedo" */ '@/components/widgets/elkPersonnelStatements/create'
    ),
  'elk.kedo.orders.widget': () =>
    import(/* webpackChunkName: "kedo" */ '@/components/widgets/elkKedoOrders'),
  'elk.service.strapi.detail.widget': () =>
    import(
      /* webpackChunkName: "service-widgets" */ '@/components/widgets/elkServiceStrapiDetail'
    ),
  'elk.service.it.strapi.detail.widget': () =>
    import(
      /* webpackChunkName: "service-widgets" */ '@/components/widgets/elkItServiceStrapiDetail'
    ),
  'elk.unavailable.widget': () =>
    import(
      /* webpackChunkName: "service-widgets" */ '@/components/widgets/elkUnavailable'
    ),
  'dpo.banner.widget': () => import('@/components/dpoWidgets/dpoBannerSl'),
  'dpo.profile.errors': () =>
    import('@/components/dpoWidgets/dpoProfileErrorWidget.vue'),
  // 'dpo.profile_detail': () =>
  //   import('@/components/dpoWidgets/dpoProfileDetail.vue'),
  'dpo.profile_detail': () =>
    import('@/components/dpoProfileForms/ProfileFormsMain'),
  'represent.profile.substitution': () =>
    import('@/components/dpoWidgets/dpoSubstitutionWidget'),
  'dpo.profile.substitution': () =>
    import('@/components/dpoWidgets/dpoSubstitutionWidget'),
  /*'dpo.schedule.widget': () =>
    import('@/components/dpoWidgets/dpoScheduleWidget'),*/
  'dpo.services.widget': () =>
    import('@/components/dpoWidgets/dpoServicesWidget'),
  'dpo.corp.services.widget': () =>
    import('@/components/dpoWidgets/dpoCorpServicesWidget'),
  'dpo.corp.main.services.widget': () =>
    import('@/components/dpoWidgets/dpoCorpServicesWidget'),
  'dpo.main.services': () =>
    import('@/components/dpoWidgets/dpoServicesWidget'),
  /*'dpo.main.schedule': () =>
    import('@/components/dpoWidgets/dpoScheduleWidget'),*/

  //'dpo.list.widget': () => import('@/components/dpoWidgets/dpoListWidget'),
  'dpo.recommendations.widget': () =>
    import('@/components/dpoWidgets/dpoRecommendedProgramsWidget'),
  'dpo.favorites.widget': () =>
    import('@/components/dpoWidgets/dpoFavoriteProgramsWidget'),
  //'dpo.news.widget': () => import('@/components/widgets/elkHomeNews/index.vue'),
  //'dpo.faq.widget': () => import('@/components/dpoWidgets/dpoFaqWidget'),
  'dpo.registration.main': () =>
    import('@/components/dpoWidgets/dpoRegistrationWidget.vue'),
  'dpo.registration.main.video': () =>
    import(
      '@/components/widgets/dpoElkProfileVideo/registrationVideoInstruction.vue'
    ),
  // 'dpo.registration.main.video': () =>
  //   import('@/components/widgets/dpoElkProfileVideo/dpoElkProfileVideo.vue'),
  'dpo.main.menu': () => import('@/components/dpoWidgets/dpoListWidget.vue'),
  'represent.registration.main': () =>
    import(
      /* webpackChunkName: "represent-registration-main" */ '@/components/representRegistration/RepresentRegistrationMain.vue'
    ),
  'represent.profile.errors': () =>
    import(
      /* webpackChunkName: "represent-profile-errors" */ '@/components/representProfile/RepresentProfileErrors'
    ),
  'represent.profile.main': () =>
    import(
      /* webpackChunkName: "represent-profile-main" */ '@/components/representProfile/RepresentProfileMain'
    ),
  'represent.profile.services': () =>
    import(
      /* webpackChunkName: "represent-profile-services" */ '@/components/dpoWidgets/dpoCorpServicesWidget'
    ),
  'represent.profile.historyactions': () =>
    import(
      /* webpackChunkName: "represent-profile-historyactions" */ '@/components/widgets/elkHistoryActions'
    ),
  'represent.legal_listeners.main': () =>
    import(
      '@/components/representLegalListeners/RepresentLegalListenersMain.vue'
    ),
  'represent.profile.edit.form': () =>
    import(
      /* webpackChunkName: "represent-profile-edit-main" */ '@/components/representProfileEdit/RepresentProfileEditForm'
    ),
  'represent.profile.edit.legal.form': () =>
    import(
      /* webpackChunkName: "represent-profile-legal-edit-main" */ '@/components/representProfileEdit/RepresentProfileEditLegalForm'
    ),
  'represent.list.main': () =>
    import(
      /* webpackChunkName: "represent-list-main" */ '@/components/representList/RepresentListMain'
    ),
  'represent.invite.form': () =>
    import(
      /* webpackChunkName: "represent-invite-form" */ '@/components/representInvite/RerpresentInviteForm'
    ),
  'represent.listener.invite.form': () =>
    import(
      /* webpackChunkName: "represent-listener-invite-form" */ '@/components/representListenerInvite/RepresentListenerInviteForm'
    ),
  'bitvuz.listener.invite.form': () =>
    import(
      /* webpackChunkName: "bitvuz-listener-invite" */ '@/components/widgets/elkRegistration' //'@/components/bitvuzListenerInvite/BitvizListenerInviteForm.vue'
    ),
  'represent.documents.contracts.list': () =>
    /* webpackChunkName: "represent-document-list" */ import(
      '@/components/representDocumentList/RepresentDocumentListMain'
    ),
  'represent.programs.widget': () =>
    import('@/components/representCatalog/representCatalogMain'),
  // represent contract detail widgets
  'dpo.represent.contract.detail.card': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentContractHeader'
    ),
  'dpo.represent.contract.detail.addition.agree': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentAgreements'
    ),
  'dpo.represent.contract.detail.doc.scan': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentScanDoc'
    ),
  'dpo.represent.contract.detail.listeners': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentListeners'
    ),
  'dpo.represent.contract.detail.history.payment': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentHistoryPayment'
    ),
  'dpo.represent.contract.detail.history.status': () =>
    /* webpackChunkName: "represent-contract-detail" */ import(
      '@/components/dpoRepresentContractDetail/widgetRepresentHistoryStatus'
    ),
  'dpo.represent.listener.selection': () =>
    import('@/components/dpoListeners/dpoListenerSelection'),
  'elk.contracts_payments.widget': () =>
    import('@/components/widgets/elkContractsPayments'),
  'elk.contract_payment.main': () =>
    import('@/components/widgets/elkContractPayment'),
  'elk.main.grants': () => import('@/components/widgets/elkGrants'),
  'elk.grants.grants': () => import('@/components/widgets/elkGrants'),
  'elk.service.pay_slip.widget': () =>
    import(
      /* webpackChunkName: "pay_slip" */ '@/components/widgets/elkPaySlip'
    ),
  'elk.student_services.widget': () =>
    import(
      /* webpackChunkName: "student_services" */ '@/components/widgets/StudentServices'
    ),
  'elk.student_service.widget': () =>
    import(
      /* webpackChunkName: "student_services" */ '@/components/widgets/StudentService'
    )
};

export default signature => {
  if (Object.prototype.hasOwnProperty.call(components, signature)) {
    return components[signature];
  }
};
