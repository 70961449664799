import localStorageService from '@/utils/localStorageService.js';

const {getLocaleId} = localStorageService;

const localeId = getLocaleId();

// локализуем рекурсивно жсон
function interpretLocalizationString(input) {
  // Определяем, какой индекс использовать в зависимости от локали
  const index = localeId === '1' ? 0 : localeId === '3' ? 1 : -1;

  if (!input || typeof input !== 'string') return input;

  const matches = input.match(/"[^"]*\|\|\|\|[^"]*"/g);
  if (matches) {
    return input.replace(/"([^"]+)\|\|\|\|([^"]+)"/g, (match, p1, p2) => {
      const parts = index === 0 ? p1 : p2;
      return `"${parts.trim()}"`;
    });
  }

  const parts = input.split('||||');

  if (parts.length === 1) {
    // Если нет разделителей
    return input;
  }

  // Если локаль не поддерживается, возвращаем строку как есть
  if (index < 0 || index >= parts.length) {
    return input;
  }

  return parts[index].trim();
}

function localizeFormJson(json) {
  if (typeof json === 'string') {
    // Если json - строка
    return interpretLocalizationString(json);
  }

  if (!json || typeof json !== 'object') {
    return json;
  }

  if (Array.isArray(json)) {
    // Обрабатываем массивы
    return json.map(item => localizeFormJson(item));
  }

  // Обрабатываем объекты
  for (const key in json) {
    if (Object.prototype.hasOwnProperty.call(json, key)) {
      const value = json[key];

      if (typeof value === 'string') {
        json[key] = interpretLocalizationString(value);
      } else if (typeof value === 'object' && value !== null) {
        json[key] = localizeFormJson(value); // Рекурсивно обрабатываем вложенные объекты
      }
    }
  }

  return json;
}

export default localizeFormJson;
